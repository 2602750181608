<template>
  <div ref="fullscreenContainer" class="full-screen-container">
    <!-- <SideControl
      v-if="no_logout && clientStatus === CONNECTED_STATUS"
      @logout="LogOut"
      @fullscreen="fullscreen"
      @disconnectKeyboard="disconnectKeyboardEvents"
    /> -->
    <div
      ref="guacamoleContainer"
      class="full-screen-container nocursor"
      v-show="clientStatus === CONNECTED_STATUS"
      @click="setupKeyboardEvents"
    ></div>
  </div>
</template>

<script>
import Guacamole from 'guacamole-common-js'
import CryptoJS from 'crypto-js'
import SideControl from './side-controls.vue'
import { Network } from '@capacitor/network'
import Swal from 'sweetalert2'

export default {
  name: 'GuacamoleStage',
  components: {
    SideControl
  },
  emits: ['display-login'],
  data() {
    return {
      client: null,
      hostname: '',
      username: '',
      password: '',
      port: '',
      scale: 1,
      token: '',
      wrapper: null,
      clientStatus: 0, // Estado inicial
      optimal_height: 0,
      optimal_width: 0,
      keyboard: null,
      no_logout: true,
      networkConnected: true,
      hasStoredCredentials: false,
      pingResult: null, // Almacena la latencia promedio
      pingIntervalId: null, // Almacena el ID del intervalo para el ping periódico
      pingsLost: false, // Rastrea si se están perdiendo pings
      reconnecting: false, // Rastrea si se está intentando reconectar
      connectionMessage: null, // Almacena el mensaje de estado de conexión actual
      nombre_maquina: ''
    }
  },
  props: {
    encryptedToken: String,
    userData: Object
  },
  computed: {
    CONNECTED_STATUS() {
      return Guacamole.Client.State.CONNECTED
    },
    DISCONNECTED_STATUS() {
      return Guacamole.Client.State.DISCONNECTED
    }
  },
  unmounted() {
    localStorage.removeItem('no_logout')
    this.cleanup()
  },
  mounted() {
    const no_logout = localStorage.getItem('no_logout')
    this.no_logout = !no_logout

    let storedData = localStorage.getItem('credentials')
    let data = storedData ? JSON.parse(storedData) : null
    if (data) {
      this.hasStoredCredentials = true
      this.hostname = data.hostname
      this.username = data.username
      this.password = data.password
      this.port = data.port
      this.nombre_maquina = data.nombre_maquina
    } else {
      if (this.userData) {
        data = this.userData
      } else if (this.encryptedToken) {
        data = this.decryptToken(this.encryptedToken)
      }

      if (data) {
        this.hostname = data.hostname
        this.username = data.username
        this.password = data.password
        this.port = data.port
        this.port = data.nombre_maquina
        console.log(data)
        localStorage.setItem('credentials', JSON.stringify(data))
      }
    }

    // Inicia el visor directamente
    this.startViewer()

    // Reemplaza el listener de 'resize' con un ResizeObserver
    this.resizeObserver = new ResizeObserver(this.resizeGuacamoleDisplay)
    if (this.$refs.guacamoleContainer) {
      this.resizeObserver.observe(this.$refs.guacamoleContainer)
    }

    // Inicia el ping periódico
    this.ping() // Ejecuta una vez inmediatamente
    this.pingIntervalId = setInterval(this.ping, 5000) // Ping cada 5 segundos
  },
  beforeUnmount() {
    // Desconectar el ResizeObserver
    if (this.resizeObserver) {
      this.resizeObserver.disconnect()
    }
    this.cleanup()
  },
  watch: {
    clientStatus(newStatus) {
      if (newStatus === this.CONNECTED_STATUS) {
        if (this.$refs.guacamoleContainer && this.client) {
          // Limpia el contenedor antes de agregar para evitar duplicados
          this.$refs.guacamoleContainer.innerHTML = ''
          this.$refs.guacamoleContainer.appendChild(this.client.getDisplay().getElement())
        }
        // Restablece el mensaje de conexión
        this.connectionMessage = null
        this.updateDisplayMessage()
      } else if (newStatus === this.DISCONNECTED_STATUS) {
        this.connectionMessage = 'Conexión perdida. Intentando reconectar...'
        this.updateDisplayMessage()
        // Maneja la desconexión
        this.handleClientDisconnected()
      }
    },
    networkConnected(newStatus) {
      if (!newStatus) {
        this.clientStatus = 5 // Estado personalizado para red desconectada
        this.connectionMessage = 'Red desconectada'
        this.updateDisplayMessage()
      }
    },
    pingResult(newPingResult) {
      // Opcional: Puedes manejar la actualización de latencia aquí si lo deseas
    }
  },
  methods: {
    retryRequests() {
      // Reintenta cualquier solicitud pendiente o reinicializa tu conexión
      if (this.clientStatus === this.DISCONNECTED_STATUS) {
        this.attemptReconnection()
      }
    },
    cleanup() {
      // Limpia el intervalo de ping para prevenir fugas de memoria
      if (this.pingIntervalId) {
        clearInterval(this.pingIntervalId)
        this.pingIntervalId = null
      }
      // Desconecta el cliente si aún está conectado
      if (this.client) {
        this.client.disconnect()
        this.client = null
      }
      // Remueve los listeners de eventos
      this.disconnectKeyboardEvents()
      // Limpieza adicional si es necesario
    },
    // Método para iniciar el visor
    startViewer() {
      this.initializeGuacamoleClient()
    },
    attemptReconnection() {
      if (this.reconnecting) return
      this.reconnecting = true
      this.cleanup()
      // Restablece clientStatus para activar la vista de conexión si tienes una
      this.clientStatus = Guacamole.Client.State.CONNECTING
      this.initializeGuacamoleClient()
      this.reconnecting = false
    },
    reconnect() {
      let hostname_code = localStorage.getItem('hostname_code')

      fetch(
        'https://panel.qinaya.co/api/mobile.asp?Action=disconnection&hostname=' +
          encodeURIComponent(hostname_code)
      )

      location.reload()
    },
    async initializeGuacamoleClient() {
      const container = this.$refs.guacamoleContainer
      console.log(container)
      if (container == 0) {
      }

      const width = container.offsetWidth
      const height = container.offsetHeight

      this.optimal_height = height
      this.optimal_width = width
      this.token =
        this.encryptToken({
          connection: {
            type: 'rdp',
            settings: {
              hostname: this.hostname,
              username: this.username,
              password: this.password,
              'enable-drive': true,
              port: '3389',
              'create-drive-path': false,
              'enable-audio-input': true,
              'enable-audio': true,
              security: 'any',
              'ignore-cert': true,
              'enable-wallpaper': true,
              'server-layout': 'es-latam-qwerty',
              'resize-method': 'display-update',
              cursor: false
            }
          }
        }) +
        `&width=${window.innerWidth}` +
        `&height=${window.innerHeight}` +
        `&GUAC_AUDIO=audio/L8&GUAC_AUDIO=audio/L16`

      this.clientStatus = Guacamole.Client.State.IDLE
      const tunnel = new Guacamole.WebSocketTunnel('wss://rafale.qinaya.co/')
      this.client = new Guacamole.Client(tunnel)

      // Maneja los cambios de estado del túnel
      tunnel.onstatechange = (state) => {
        switch (state) {
          case Guacamole.Tunnel.State.CONNECTING:
            console.log('Tunnel is connecting')
            this.connectionMessage = 'Conectando!'
            this.updateDisplayMessage()
            break
          case Guacamole.Tunnel.State.OPEN:
            console.log('Tunnel is open')
            this.connectionMessage = 'Conectado!'
            this.updateDisplayMessage()
            break
          case Guacamole.Tunnel.State.UNSTABLE:
            console.log('Tunnel is unstable')
            this.connectionMessage = 'Conexión perdida!'
            this.updateDisplayMessage()
            break
          case Guacamole.Tunnel.State.CLOSED:
            console.log('Tunnel is closed')
            this.connectionMessage = 'Conexión perdida. Intentando reconectar...'
            this.updateDisplayMessage()
            this.handleTunnelClosed()
            break
        }
      }

      tunnel.onerror = (status) => {
        console.error(`Tunnel error: ${JSON.stringify(status)}`)
        this.connectionMessage = 'Conexión perdida. Intentando reconectar...'
        this.updateDisplayMessage()
        this.handleTunnelError(status)
      }

      this.setupKeyboardEvents()
      this.setupMouseEvents()
      this.setupTouchEvents()

      this.client.onstatechange = (state) => {
        this.clientStatus = state
        console.log('Client State: ' + state)
        switch (state) {
          case Guacamole.Client.State.IDLE:
            // Maneja el estado idle
            break
          case Guacamole.Client.State.CONNECTING:
            // Maneja el estado connecting
            this.connectionMessage = 'Conectando...'
            this.updateDisplayMessage()
            break
          case Guacamole.Client.State.WAITING:
            // Maneja el estado waiting
            this.connectionMessage = 'Esperando...'
            this.updateDisplayMessage()
            break
          case Guacamole.Client.State.CONNECTED:
            console.log('Client connected, initializing audio...')
            if (!this.client) return

            this.fullscreen()

            this.requestAudioStream()
            // Limpia cualquier mensaje de conexión
            this.connectionMessage = null
            this.updateDisplayMessage()
            break
          case Guacamole.Client.State.DISCONNECTING:
            // Maneja el estado disconnecting
            console.log('Client is disconnecting...')
            break
          case Guacamole.Client.State.DISCONNECTED:
            // Maneja el estado disconnected
            console.log('Client is disconnected.')
            this.connectionMessage = 'Conexión perdida. Intentando reconectar...'
            this.updateDisplayMessage()
            this.handleClientDisconnected()
            break
        }
      }

      this.client.onerror = (error) => {
        console.error(`Client error: ${JSON.stringify(error)}`)
        if (this.client) {
          this.client.disconnect()
        }
        this.connectionMessage = 'Conexión perdida. Intentando reconectar...'
        this.updateDisplayMessage()
        this.handleClientError(error)
      }

      this.client.connect('token=' + this.token)

      Network.addListener('networkStatusChange', (status) => {
        console.log('Network status changed', status)
        this.networkConnected = status.connected

        if (status.connected) {
          console.log('Red reconectada, recargando la página...')
          // Muestra un mensaje de carga antes de recargar
          this.connectionMessage = 'Red reconectada. Recargando...'
          this.updateDisplayMessage()
          let hostname_code = localStorage.getItem('hostname_code')

          fetch(
            'https://panel.qinaya.co/api/mobile.asp?Action=disconnection&hostname=' +
              encodeURIComponent(hostname_code)
          )
          setTimeout(() => {
            Swal.close() // Cierra el mensaje SweetAlert
            location.reload()
          }, 1000) // Retardo de 1 segundo
        } else {
          this.connectionMessage = 'Red desconectada'
          this.updateDisplayMessage()
        }
      })
    },
    updateDisplayMessage() {
      if (this.connectionMessage) {
        // Seleccionar la imagen según el mensaje de conexión
        let imageUrl = ''
        switch (this.connectionMessage) {
          case 'Conectando!':
            imageUrl = '/images/rafale.png'
            break
          case 'Conectado!':
            imageUrl = '/images/rafale.png'
            break
          case 'Conexión perdida!':
            imageUrl = '/images/noint.png'
            break
          case 'Conexión perdida. Intentando reconectar...':
          case 'Conexión perdida. Reconectando...':
            imageUrl = '/images/noint.png'
            break
          case 'Red desconectada':
            imageUrl = '/images/noint.png'
            break
          case 'Conectando...':
            imageUrl = '/images/rafale.png'
            break
          case 'Esperando...':
            imageUrl = '/images/waiting.png'
            break
          default:
            imageUrl = '/images/rafale.png'
            break
        }

        Swal.fire({
          title: this.connectionMessage,
          imageUrl: imageUrl,
          imageWidth: '60%', // Ajusta el ancho según tus necesidades
          allowOutsideClick: true,
          allowEscapeKey: true,
          showConfirmButton: false,
          backdrop: true
        })
      } else {
        Swal.close()
      }
    },
    handleTunnelClosed() {
      console.log('Handling tunnel closed')
      this.clientStatus = Guacamole.Client.State.DISCONNECTED
      this.connectionMessage = 'Conexión perdida. Intentando reconectar...'
      this.updateDisplayMessage()
      let hostname_code = localStorage.getItem('hostname_code')

      fetch(
        'https://panel.qinaya.co/api/mobile.asp?Action=disconnection&hostname=' +
          encodeURIComponent(hostname_code)
      )
      setTimeout(() => {
        Swal.close() // Cierra el mensaje SweetAlert
        location.reload()
      }, 1000) // Retardo de 1 segundo
    },
    handleTunnelError(status) {
      console.log('Handling tunnel error')
      this.clientStatus = Guacamole.Client.State.DISCONNECTED
      this.connectionMessage = 'Conexión perdida. Intentando reconectar...'
      this.updateDisplayMessage()
      let hostname_code = localStorage.getItem('hostname_code')

      fetch(
        'https://panel.qinaya.co/api/mobile.asp?Action=disconnection&hostname=' +
          encodeURIComponent(hostname_code)
      )
      setTimeout(() => {
        Swal.close() // Cierra el mensaje SweetAlert
        location.reload()
      }, 1000) // Retardo de 1 segundo
    },
    handleClientDisconnected() {
      console.log('Handling client disconnection')
      // Actualiza clientStatus para mostrar el mensaje de desconexión
      this.clientStatus = Guacamole.Client.State.DISCONNECTED
      this.connectionMessage = 'Conexión perdida. Intentando reconectar...'
      this.updateDisplayMessage()
      let hostname_code = localStorage.getItem('hostname_code')

      fetch(
        'https://panel.qinaya.co/api/mobile.asp?Action=disconnection&hostname=' +
          encodeURIComponent(hostname_code)
      )
      setTimeout(() => {
        Swal.close() // Cierra el mensaje SweetAlert
        location.reload()
      }, 1000) // Retardo de 1 segundo
    },
    handleClientError(error) {
      console.log('Handling client error')
      // Maneja el error del cliente
      this.clientStatus = Guacamole.Client.State.DISCONNECTED
      this.connectionMessage = 'Ocurrió un error. Intentando reconectar...'
      this.updateDisplayMessage()
      setTimeout(() => {
        Swal.close() // Cierra el mensaje SweetAlert
        this.attemptReconnection()
      }, 1000) // Retardo de 1 segundo
    },
    requestAudioStream() {
      if (!this.client) return
      let stream = this.client.createAudioStream(`audio/L16;rate=44100,channels=2`)
      let recorder = Guacamole.AudioRecorder.getInstance(stream, 'audio/L16;rate=44100,channels=2')
      if (!recorder) {
        stream.sendEnd()
      } else {
        recorder.onclose = this.requestAudioStream.bind(this.client)
      }
    },
    resizeGuacamoleDisplay(entries) {
      if (!this.client) return

      let newWidth, newHeight

      if (entries && entries.length > 0) {
        // Si se llama desde ResizeObserver
        const entry = entries[0]
        newWidth = entry.contentRect.width
        newHeight = entry.contentRect.height
      } else {
        // Fallback si entries no está disponible
        const container = this.$refs.guacamoleContainer
        newWidth = container.offsetWidth
        newHeight = container.offsetHeight
      }

      // Enviar el nuevo tamaño al cliente Guacamole
      this.client.sendSize(newWidth, newHeight)
    },
    fullscreen() {
      console.log('Activando pantalla completa')
      const elem = this.$refs.fullscreenContainer

      if (!document.fullscreenElement) {
        console.log('Entrando en pantalla completa')

        if (elem.requestFullscreen) {
          elem.requestFullscreen()
        } else if (elem.mozRequestFullScreen) {
          /* Firefox */
          elem.mozRequestFullScreen()
        } else if (elem.webkitRequestFullscreen) {
          /* Chrome, Safari & Opera */
          elem.webkitRequestFullscreen()
        } else if (elem.msRequestFullscreen) {
          /* IE/Edge */
          elem.msRequestFullscreen()
        }
      } else {
        console.log('Saliendo de pantalla completa')

        if (document.exitFullscreen) {
          document.exitFullscreen()
        } else if (document.mozCancelFullScreen) {
          /* Firefox */
          document.mozCancelFullScreen()
        } else if (document.webkitExitFullscreen) {
          /* Chrome, Safari and Opera */
          document.webkitExitFullscreen()
        } else if (document.msExitFullscreen) {
          /* IE/Edge */
          document.msExitFullscreen()
        }
      }
    },
    setupKeyboardEvents() {
      if (this.keyboard != null) return
      if (!this.client) return
      console.log('Configurando eventos de teclado')

      const guac = this.client
      this.keyboard = new Guacamole.Keyboard(document)

      this.keyboard.onkeydown = function (keysym) {
        guac.sendKeyEvent(1, keysym)
      }

      this.keyboard.onkeyup = function (keysym) {
        guac.sendKeyEvent(0, keysym)
      }
    },
    disconnectKeyboardEvents() {
      console.log('Desconectando eventos de teclado')
      if (this.keyboard) {
        this.keyboard.onkeydown = null
        this.keyboard.onkeyup = null
        this.keyboard = null
      }
    },
    setupMouseEvents() {
      if (!this.client) return
      const guac = this.client
      const displayElement = guac.getDisplay().getElement()
      const mouse = new Guacamole.Mouse(displayElement)

      mouse.onmousedown =
        mouse.onmouseup =
        mouse.onmousemove =
          (mouseState) => {
            guac.sendMouseState(mouseState)
          }
    },
    setupTouchEvents() {
      if (!this.client) return
      const guac = this.client
      const mouse = new Guacamole.Mouse.Touchpad(guac.getDisplay().getElement())

      mouse.onmousedown =
        mouse.onmouseup =
        mouse.onmousemove =
          function (mouseState) {
            guac.sendMouseState(mouseState)
          }
    },
    encryptToken(value) {
      const SECRET_KEY = 'YJdej0QhgdJ7Phagg9k0CFob9p3mbYpY'
      const iv = CryptoJS.lib.WordArray.random(128 / 8)

      const key = CryptoJS.enc.Utf8.parse(SECRET_KEY)

      const encrypted = CryptoJS.AES.encrypt(JSON.stringify(value), key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
      })

      const data = {
        iv: CryptoJS.enc.Base64.stringify(iv),
        value: encrypted.toString()
      }

      const json = JSON.stringify(data)
      return CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(json))
    },
    decryptToken(encryptedToken) {
      const SECRET_KEY = 'YJdej0QhgdJ7Phagg9k0CFob9p3mbYpY'

      const decodedData = CryptoJS.enc.Base64.parse(encryptedToken).toString(CryptoJS.enc.Utf8)
      const data = JSON.parse(decodedData)

      const iv = CryptoJS.enc.Base64.parse(data.iv)
      const encryptedValue = data.value

      const key = CryptoJS.enc.Utf8.parse(SECRET_KEY)

      const decrypted = CryptoJS.AES.decrypt(encryptedValue, key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
      })

      const decryptedValue = CryptoJS.enc.Utf8.stringify(decrypted)
      return JSON.parse(decryptedValue)
    },
    LogOut() {
      localStorage.clear()
      this.cleanup()
      this.clientStatus = 0
      this.disconnectKeyboardEvents()
      this.$emit('display-login', null)
    },
    // Función onePing para medir la latencia de una sola solicitud
    async onePing() {
      let pingTime = 0.0

      let options = {
        method: 'GET',
        mode: 'no-cors',
        cache: 'no-cache'
      }

      let start = Date.now()
      try {
        await fetch('https://ping.qinaya.co/', options)
        pingTime = Date.now() - start
      } catch (error) {
        pingTime = -1
      }
      return pingTime
    },
    // Función ping para calcular la latencia promedio y actualizar pingResult
    async ping() {
      let packetsSent = 4
      let packetsReceived = 0
      let packetsLost = 0
      let minTime = 10000000000.0
      let maxTime = 0.0
      let totalTime = 0.0
      let avgTime = 0.0
      let prevTime = 0.0
      let jitter = 0.0

      // Los primeros dos pings se descartan ya que podrían ser inusualmente largos
      await this.onePing()
      await this.onePing()

      for (let i = 0; i < packetsSent; i++) {
        let pingTime = await this.onePing()
        if (pingTime == -1) {
          packetsLost++
        } else {
          packetsReceived++
          totalTime += pingTime
          if (pingTime < minTime) minTime = pingTime
          if (pingTime > maxTime) maxTime = pingTime

          if (i > 0) jitter += Math.abs(pingTime - prevTime)
          prevTime = pingTime
        }
      }

      if (packetsLost > 0 || packetsSent < 2) jitter = null
      else jitter = jitter / (packetsReceived - 1)

      if (packetsReceived > 0) {
        avgTime = (totalTime / packetsReceived).toFixed(2) // Redondea a 2 decimales

        let data = new FormData()
        let hostname_code = localStorage.getItem('hostname_code')

        data.append('hostname', hostname_code)
        data.append('packets_sent', packetsSent)
        data.append('packets_lost', packetsLost)
        data.append('average_time', avgTime)
        data.append('min_time', minTime)
        data.append('max_time', maxTime)
        data.append('jitter', jitter)

        let sURL = new URLSearchParams(data).toString()
        console.log(sURL)
        fetch('https://panel.qinaya.co/api/mobile.asp?action=ping&' + sURL)

        // Si los pings se habían perdido previamente y ahora tenemos un ping exitoso, recarga la página
        if (this.pingsLost) {
          console.log('Pings reestablecidos, recargando la página...')
          this.pingsLost = false
          // Muestra un mensaje de carga antes de recargar
          this.connectionMessage = 'Pings reestablecidos. Recargando...'
          this.updateDisplayMessage()
          let hostname_code = localStorage.getItem('hostname_code')

          fetch(
            'https://panel.qinaya.co/api/mobile.asp?Action=disconnection&hostname=' +
              encodeURIComponent(hostname_code)
          )
          setTimeout(() => {
            Swal.close() // Cierra el mensaje SweetAlert
            location.reload()
          }, 1000) // Retardo de 1 segundo
        }
      } else {
        avgTime = 'N/A'

        // Si se pierden los pings, establece pingsLost en true
        if (!this.pingsLost) {
          console.log('Pings perdidos, monitoreando para reconexión...')
          this.pingsLost = true
          this.connectionMessage = 'Problemas de red detectados. Intentando reconectar...'
          this.updateDisplayMessage()
        }
      }

      // Actualiza la propiedad pingResult con la latencia promedio
      this.pingResult = avgTime
      console.log('Latencia promedio:', this.pingResult, 'ms') // Opcional: registra el resultado en la consola
    }
  }
}
</script>

<style scoped>
.full-screen-container {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  position: relative;
}

.nocursor {
  cursor: none;
}

.nocursor:hover {
  cursor: none;
}
</style>

